import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// Own components
import Product from '../containers/Product';
import Layout from '../components/Layout/es';

// Assets & Misc
import { getQueryParam } from '../utils/getters';
import { navigateTo } from '../utils/navigation';
import { productsFamily } from '../constants/products';

const ProductPage = () => {
  const familyId = getQueryParam('family');
  const productId = getQueryParam('product');
  const family = productsFamily[familyId] || {};
  const product = (family.products || []).find(({ id }) => id === productId);
  const subCategoryData = useMemo(() => (family.subcategories || []).find(sub => sub.id === product.subcategory), []) || {};
  const categoryData = useMemo(() => (family.categories || []).find(cat => cat.id === product.category || subCategoryData.category), []) || {};
  const coverImage = categoryData.image || `/images/products/${familyId}.jpeg`;

  if (!product) {
    navigateTo('/404');
    return null;
  }

  return (
    <Layout>
      <Product {...product} coverImage={coverImage} />
    </Layout>
  );
};

ProductPage.propTypes  = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired
};

export default ProductPage;